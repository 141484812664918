
import { bool, nullable, oneOfType, string } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    background: oneOfType([string(), nullable()]),
    topPadding: bool().def(true),
  },
  computed: {
    footer() {
      return this.$store.state.footer;
    },
  },
};
