// const Color = require('color');

// const alpha = (clr, val) => Color(clr).alpha(val).rgb().string();
// const lighten = (clr, val) => Color(clr).lighten(val).rgb().string();
// const darken = (clr, val) => Color(clr).darken(val).rgb().string();

const PRIMARY = '#1e80ed';

const colors = {
  current: 'currentColor',
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',
  primary: PRIMARY,
  blue: {
    DEFAULT: '#1e80ed',
    25: '#f3f8fe',
    50: '#e8f2fd',
    100: '#d2e5fb',
    200: '#a5ccf7',
    300: '#85b9f5',
    400: '#4b99f0',
    450: '#348cee',
    475: '#2986ed',
    500: '#1e80ed',
    525: '#1c79e0',
    550: '#1a72d4',
    600: '#1866bd',
    700: '#124c8e',
    800: '#0c335e',
    850: '#082646',
    950: '#020c17',
  },
  turquoise: {
    DEFAULT: '#1ee8ed',
    25: '#f4fefe',
    50: '#e8fcfd',
    100: '#d2fafb',
    200: '#a5f6f8',
    300: '#78f1f4',
    400: '#4bedf1',
    500: '#1ee8ed',
    525: '#1cdce0',
    550: '#1ad0d4',
    600: '#18b9bd',
    700: '#128b8e',
    800: '#0c5c5e',
  },
  yellow: {
    DEFAULT: '#ffdb4e',
    25: '#fffdf5',
    50: '#fffbed',
    100: '#fff7db',
    200: '#fff0b8',
    300: '#ffe994',
    400: '#ffe271',
    500: '#ffdb4e',
    525: '#f2cf4a',
    550: '#e5c446',
    600: '#ccaf3e',
    700: '#99832e',
    800: '#66571f',
  },
  red: {
    DEFAULT: '#ff6d8c',
    25: '#fff7f9',
    50: '#fff0f3',
    100: '#ffe2e8',
    200: '#ffc5d1',
    300: '#ffa7ba',
    400: '#ff8aa3',
    500: '#ff6d8c',
    525: '#ff6d8c',
    550: '#e5617d',
    600: '#cc5770',
    700: '#b24c61',
    800: '#994154',
  },
  green: {
    DEFAULT: '#0deba5',
    25: '#f3fefa',
    50: '#e6fdf6',
    100: '#cffbed',
    200: '#9ef7db',
    300: '#6ef3c9',
    400: '#3defb7',
    500: '#0deba5',
    525: '#0cdf9c',
    550: '#0bd394',
    600: '#0abc84',
    700: '#09a473',
    800: '#078d63',
  },
  gray: {
    DEFAULT: '#082646',
    25: '#f2f3f5',
    50: '#e6e9ec',
    100: '#cdd3da',
    200: '#9ca8b5',
    300: '#6a7c90',
    400: '#39516b',
    450: '#213c58',
    475: '#14314f',
    500: '#082646',
    550: '#061e38',
  },
  dark: {
    DEFAULT: '#192532',
    25: '#f2f3f3',
    50: '#e5e6e7',
    100: '#ccced1',
    200: '#9a9ea2',
    300: '#676d74',
    350: '#4d545c',
    400: '#353d45',
    450: '#1b242e',
    475: '#0e1822',
    500: '#192532',
  },
};

module.exports = colors;
