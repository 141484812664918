import colors from '../_tailwind/tailwind.config.colors';

export default {
  transition(to) {
    return {
      name: 'fade',
      mode: 'out-in',
      // css: false,
      beforeEnter() {
        this.$i18n.finalizePendingLocaleChange();
      },
      leave() {
        const bodyStyles = document.body.style;
        const regex = /\_(.*)/i; // eslint-disable-line

        switch (to.name.replace(regex, '')) {
          case 'whatWeDo':
          case 'whatWeDoCapabilities':
          case 'whatWeDoFocusAreas':
            bodyStyles.setProperty('--overlay-color', colors.blue[50]);
            break;
          case 'whoWeAre':
          case 'whoWeArePeopleAndCulture':
          case 'whoWeAreOurStory':
          case 'howWeWorkOurBelief':
          case 'whoWeAreMissionAndVision':
          case 'index':
          case 'howWeWorkOurApproach':
            bodyStyles.setProperty('--overlay-color', colors.blue[500]);
            break;
          case 'howWeWorkLab':
          case 'industries':
          case 'careerHiringProcess':
          case 'careers':
          case 'careerOverview':
          case 'careerCategories':
            bodyStyles.setProperty('--overlay-color', colors.dark[475]);
            break;
          default:
            bodyStyles.setProperty('--overlay-color', colors.white);
        }
      },
    };
  },
};
