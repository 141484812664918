export default {
  watch: {
    /**
     * When the page is loaded, its content will be rendered.
     */
    entry(value) {
      if (value) {
        this.unlockTracking();
      }
    },
  },
  mounted() {
    if (this.entry) {
      this.unlockTracking();
    }
  },
  created() {
    const { id, title, url, slug, section, type } =
      this.entry || this.category || {};
    this.$store.commit('page', {
      id,
      title,
      url,
      slug,
      section,
      type,
    });
  },
  methods: {
    formatHead(seomatic) {
      const head = {
        __dangerouslyDisableSanitizers: ['script'],
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
      };

      if (!seomatic) {
        this.$store.commit('languageSwitch', {});
        return head;
      }

      // Convert the graphql JSON data to an object so we can work with it
      // Based on https://github.com/ben-rogerson/nuxt-seomatic-meta
      const {
        metaTitleContainer: {
          title: { title },
        },
        metaTagContainer,
        metaLinkContainer,
      } = Object.entries(seomatic).reduce((acc, [key, value]) => {
        if (key !== '__typename') acc[key] = JSON.parse(value);
        return acc;
      }, {});

      head.title = title;

      // Flatten metaTagContainer values into string
      head.meta = [
        ...(metaTagContainer
          ? Object.values(metaTagContainer).reduce((flat, next) => {
              if (next.name === 'description') {
                // Override description tag with updated description
                next.hid = 'description';
              }
              return flat.concat(next);
            }, [])
          : null),
      ];

      // Flatten metaLinkContainer values into string
      head.link = metaLinkContainer
        ? Object.values(metaLinkContainer).reduce(
            (flat, next) => flat.concat(next),
            []
          )
        : null;

      const languages = head.link.reduce((prev, currentLink) => {
        if (
          currentLink.rel === 'alternate' &&
          currentLink.hreflang.length === 2
        ) {
          return { ...prev, [currentLink.hreflang]: currentLink.href };
        }

        return prev;
      }, {});
      this.$store.commit('languageSwitch', languages);

      return head;
    },
    unlockTracking() {
      this.$store.commit('scroll/unlockTracking');
    },
  },
};
